export default {
    navIntro: '开启数字化学习征程',
    Home: "首页",
    myCambook:'My CamBook',
    Digital: "教材数字化",
    DigitalTitle1: "全在一“书”之中",
    DigitalIntro1: "提供电子版课程使用的书籍，以及多种智能化学习工具，帮助高效便捷的复习所学内容。",
    DigitalTitle2: "全在一“链”之间",
    DigitalIntro2: "自动链接课程教材和课程知识要点，便于参考和研究。",
    DigitalTitle3: "随时随地“听”",
    DigitalIntro3: "在练习或其他任何时候，通过听相关内容的有声书，让知识流入脑海。",
    DigitalTitle4: "随时随地“写”",
    DigitalIntro4: "不管是在听讲还是在阅读，能够轻松创建和编辑学习笔记，不让脑海遗漏任何内容。",
    DigitalTitle5: "随时随地“说”",
    DigitalIntro5: "与学习伙伴互帮互助，互相学习，逐步深入理解知识并巩固所学知识。",
    Customizable: "教材定制化",
    CustomizableTitle1: "政治把关",
    CustomizableIntro1: "帮助讲师和机构审核教材，确保所有学习材料都符合国家要求，不存在政治错误和监管错误。",
    CustomizableTitle2: "定制服务",
    CustomizableIntro2: "提供定制采购服务，学生无需购买整本教科，只需根据课程建议购买所需章节。",
    Environmentally: "教材绿色化",
    EnvironmentallyTitle1: "低碳运输",
    EnvironmentallyIntro1: "无需运送纸质书，减少碳排放。将 100,000 本书从新泽西空运到上海（11873 公里），将产生 5000 公斤碳。",
    EnvironmentallyTitle2: "节能减排",
    EnvironmentallyIntro2: "不再需要纸质书印刷，化学物质排放量将相应下降。",
    EnvironmentallyTitle3: "保护森林",
    EnvironmentallyIntro3: "减少纸张消耗和树木砍伐，帮助重建绿色环境。减少 100,000 本书的产量，可拯救 2600 棵完全成熟的树木。",
    Simple: "管理简便化",
    SimpleTitle1: "零库存",
    SimpleIntro1: "节省管理图书库存的成本，避免图书损坏的风险。",
    SimpleTitle2: "零接触",
    SimpleIntro2: "减少在循环过程中,尤其是在新型冠状病毒流行期间被细菌污染的可能性。",
    SimpleTitle3: "零分发",
    SimpleIntro3: "不需要清点和分发书籍，避免工作失误带来的后续问题。",
    SimpleTitle4: "零等待",
    SimpleIntro4: "下单或输入兑换码后立即获得电子书，无需运输和交付即买即得。",
    BookName: "人力资源和人才规划",
    About: "关于我们",
    AboutCamBook: "关于CamBook",
    Cooperative: "合作",
    OperationTutorial: "信息反馈",
    FeedbackInformation: "帮助",
    Contect: "联系方式",
    CamBookResources: "资源",
    DigitalTextbooks: "电子教科书",
    BookStore: "网上书店", 
    PartnerShip: "合作伙伴",
    University: "大学",
    Publisher: "出版机构",
    Download: "下载",
    BookName1: "研究和撰写专题论文",
    BookName2: "第四纪末期环境变化",
    BookName3: "商学院学生手册",
    BookName4: "工程有限元导论 ",
    BookName5: "关系营销",
    BookName6: "如何教口语",
    BookName7:"交互设计",
}
