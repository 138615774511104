export default {
    navIntro: 'The revolution of digital learning',
    Home: "Home",
    myCambook:'My CamBook',
    Digital: "Digital Learning",
    DigitalTitle1: "All in One Book",
    DigitalIntro1: "Provides all course books in eBook format, with several smart studying tools to help students to review what they've learned. ",
    DigitalTitle2: "Courses Knowledge Linked",
    DigitalIntro2: "Automatically link the course textbook and course knowledge key-points, easier to make reference and researching. ",
    DigitalTitle3: "Book Talk",
    DigitalIntro3: "Listening to an audio-book while training and etc. and let the knowledge flow into your mind.",
    DigitalTitle4: "Lecture Notes & Reading Notes",
    DigitalIntro4: " Easily creating and editing notes during lecutres or reading, don't let anything flash away from your mind. ",
    DigitalTitle5: "Study Groups",
    DigitalIntro5: "Helping each other to deepen the understanding and consoliddate the knowledge learned. ",
    Customizable: "Customizable",
    CustomizableTitle1: "Political Evaluation",
    CustomizableIntro1: "Help lecturers and institutions to review the textbooks, make sure all learning materials meet the national requirements with no political andregulatory mistaken.",
    CustomizableTitle2: "Adaptation",
    CustomizableIntro2: "Provides customized purchasing services, students only need to buy the required chapters according to the course suggestions, rather than the whole textbook.",
    Environmentally: "Environmentally Friendly",
    EnvironmentallyTitle1: "Shipping",
    EnvironmentallyIntro1: "There is no need to ship paper books after using eBooks, in case to redue the carbon emissions. 5,000kg carbon will be generated to ship 100,000 books from New Jersey to Shanghai (11,873km) by air.",
    EnvironmentallyTitle2: "Printing",
    EnvironmentallyIntro2: "Paper book printing is no longer needed, the chemical emissions have accordingly declined.",
    EnvironmentallyTitle3: "Paper",
    EnvironmentallyIntro3: "Reducing paper consumption and the tree cuts,helping rebuild a green environment.Reduce the production of 100,000 books could save 2,600 fully grown trees.",
    Simple: "Simple Administration",
    SimpleTitle1: "0 Inventory",
    SimpleIntro1: "Save the costs of managing the books inventory and avoid the risk of book damage.",
    SimpleTitle2: "0 Germ",
    SimpleIntro2: "Reduce the possibility of being contaminated by bacteria during circulation, especially during the COVID-19.",
    SimpleTitle3: "0 Distribution",
    SimpleIntro3: "No longer necessary to organize the resources to count and distribute books, also avoids thefollow-up problems caused by work mistakes.",
    SimpleTitle4: "0 Waiting",
    SimpleIntro4: "Users get their eBooks immediately after placing an order or entering a redeem code, without wasting time on waiting logistics transportation and delivery.",
    BookName: "People Resourcing and Talent Planning",
    About: "About Us",
    AboutCamBook: "About CamBook",
    Cooperative: "Cooperative",
    Contect: "Contact Us",
    CamBookResources: "CamBook Resources",
    DigitalTextbooks: "Digital Textbooks",
    BookStore: "Bookstores",
    OperationTutorial: "Tutorial",
    FeedbackInformation: "Feedback",
    PartnerShip: "Partners",
    University: "Universities",
    Publisher: "Publishers",
    Download: "Downloads",
    BookName1: "Researching And Writing A Dissertation",
    BookName2: "Late Quaternary Environmental Change",
    BookName3: "Business Student's Handbook",
    BookName4: "Introduction to Finite Elements in Engineering",
    BookName5: "Relationship Marketing",
    BookName6: "How To Teach Speaking",
    BookName7:"Interaction Design",
}