<template>
    <div class="Home">
        <div class="header">
            <img class="logo" src="@/assets/images/CamBookLogo.png" alt="" />
            <div class="headerRight">
                <div class="headerRightItem headerRightMar" @click="goCambook">{{ $t("myCambook") }}</div>
                <!-- <div class="headerRightItem headerRightMar">{{ $t("About") }}</div> -->
                <div class="headerRightL" @click="chooseShow">
                    <div>{{lang}}</div>
                    <div class="trigleBox"><span class="trigle"></span></div>
                    <div class="pop" v-if="Boolean(isChooseShow)">
                        <div v-for="(item,index) in lanagelist" :key="index" @click.stop="chooesLange(item[0],item[1])">
                            {{item[1]}}
                        </div>
                    </div>
                    </div>
                    
            </div>
        </div>
        <div class="nav">
            <img class="CamBookNavLeft" src="@/assets/images/CamBookNavLeft.png" alt=""/>
            <img class="CamBookNavRioght" src="@/assets/images/CamBookNavRioght.png" alt=""/>
            <div class="navTitle">{{ $t("navIntro") }}</div>
            <img class="navImg" src="@/assets/images/CamBookNav.png" alt=""/>
        </div>
        <div class="DigitalLearning">
            <div class="DigitalLearningTitle">
                <div>{{ $t("Digital") }}</div>
                <img class="DigitalLearningImgLittle" src="@/assets/images/DigitalLearningImg.png" alt="" />
            </div>
            <div class="DigitalLearningContext">
                <div class="DigitalLeft">
                    <div class="DigitalLeftIntro">
                        <img class="DigitalLeftIntroLittle" src="@/assets/images/all.png" alt="" />
                        <div class="">
                            <div class="DigitalLeftIntroTitle">{{ $t("DigitalTitle1") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("DigitalIntro1") }}</div>
                        </div>
                        
                    </div>
                    <div class="DigitalLeftIntro">
                        <img class="DigitalLeftIntroLittle" src="@/assets/images/course.png" alt="" />
                        <div >
                            <div class="DigitalLeftIntroTitle">{{ $t("DigitalTitle2") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("DigitalIntro2") }}</div>
                        </div>
                        
                    </div>
                    <div class="DigitalLeftIntro">
                        <img class="DigitalLeftIntroLittle" src="@/assets/images/notes.png" alt="" />
                        <div >
                            <div class="DigitalLeftIntroTitle">{{ $t("DigitalTitle3") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("DigitalIntro3") }}</div>
                        </div>
                        
                    </div>
                </div>
                <img class="DigitalLearningImg" src="@/assets/images/DigitalLearningImg.png" alt="" />
                <div class="DigitalLeft">
                    <div class="DigitalRightIntro">
                        <img class="DigitalLeftIntroLittle" src="@/assets/images/study-group.png" alt="" />
                        <div>
                            <div class="DigitalLeftIntroTitle">{{ $t("DigitalTitle4") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("DigitalIntro4") }}</div>
                        </div>
                        
                    </div>
                    <div class="DigitalRightIntro">
                        <img class="DigitalLeftIntroLittle" src="@/assets/images/talk.png" alt="" />
                        <div>
                            <div class="DigitalLeftIntroTitle">{{ $t("DigitalTitle5") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("DigitalIntro5") }}</div>
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
        <div class="DigitalLearning Customizable">
            <div class="DigitalLearningTitle">
                <div>{{ $t("Customizable") }}</div>
                <img class="DigitalLearningImgLittle" src="@/assets/images/CustomizableImgLittle.png" alt="" />
            </div>
            <div class="DigitalLearningContext CustomizableContext">
                <div class="CustomizableLeft">
                    <img class="CustomizableLeftImg" src="@/assets/images/CustomizableLeftImg.png" alt="" />
                    <div class="CustomizableLeftIntro">
                        <div class="DigitalLeftIntroTitle">{{ $t("CustomizableTitle1") }}</div>
                        <div class="DigitalLeftIntroDetail">{{ $t("CustomizableIntro1") }}</div>
                    </div>
                </div>
                <img class="CustomizableImg" src="@/assets/images/CustomizableImg.png" alt="" />
                <div class="CustomizableLeft CustomizableRight">
                    <img class="CustomizableRightImg" src="@/assets/images/CustomizableRightImg.png" alt="" />
                    <div class="CustomizableRightIntro">
                        <div class="DigitalLeftIntroTitle">{{ $t("CustomizableTitle2") }}</div>
                        <div class="DigitalLeftIntroDetail">{{ $t("CustomizableIntro2") }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="DigitalLearning">
            <div class="DigitalLearningTitle">
                <div>{{ $t("Environmentally") }}</div>
                <img class="DigitalLearningImgLittle" src="@/assets/images/EnvironmentallyLittle.png" alt="" />
            </div>
            <div class="DigitalLearningContext">
                <div class="DigitalLeft EnvironmentallyLeft">
                    <div class="DigitalLeftIntro">
                        <img class="DigitalLeftIntroLittle" src="@/assets/images/shipping.png" alt="" />
                        <div>
                            <div class="DigitalLeftIntroTitle">{{ $t("EnvironmentallyTitle1") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("EnvironmentallyIntro1") }}</div>
                        </div>
                        
                    </div>
                    <div class="DigitalLeftIntro">
                        <img class="DigitalLeftIntroLittle" src="@/assets/images/Printing.png" alt="" /> 
                        <div>
                            <div class="DigitalLeftIntroTitle">{{ $t("EnvironmentallyTitle2") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("EnvironmentallyIntro2") }}</div>
                        </div>
                        
                    </div>
                </div>
                <img class="Environmentally" src="@/assets/images/Environmentally.png" alt="" />
                <div class="DigitalLeft EnvironmentallyRight">
                    <div class="DigitalRightIntro">
                        <img class="DigitalLeftIntroLittle" src="@/assets/images/book.png" alt="" /> 
                        <div>
                            <div class="DigitalLeftIntroTitle">{{ $t("EnvironmentallyTitle3") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("EnvironmentallyIntro3") }}</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="DigitalLearning Customizable">
            <div class="DigitalLearningTitle">
                <div>{{ $t("Simple") }}</div>
                <img class="DigitalLearningImgLittle SimpleImgLittle" src="@/assets/images/SimpleImgLittle.png" alt="" />
            </div>
            <div class="DigitalLearningContext">
                <div class="DigitalLeft EnvironmentallyLeft">
                    <div class="SimpleItem">
                        <div class="DigitalLeftIntro DigitalLeftIntroductLittle EnvironmentallyLittle">
                            <div class="DigitalLeftIntroTitle">{{ $t("SimpleTitle1") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("SimpleIntro1") }}</div>
                        </div>
                        <img class="SimpleItemImg" src="@/assets/images/InventoryImg.png" alt="" />
                    </div>
                    <div class="SimpleItem">
                        <div class="DigitalLeftIntro DigitalLeftIntroductLittle EnvironmentallyLittle">
                            <div class="DigitalLeftIntroTitle">{{ $t("SimpleTitle2") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("SimpleIntro2") }}</div>
                        </div>
                        <img class="SimpleItemImg" src="@/assets/images/GermImg.png" alt="" />
                    </div>
                </div>
                <img class="SimpleImg" src="@/assets/images/SimpleImg.png" alt="" />
                <div class="DigitalLeft EnvironmentallyLeft">
                    <div class="SimpleItemReversal">
                        <div class="DigitalRightIntro DigitalLeftIntroductLittle EnvironmentallyLittle">
                            <div class="DigitalLeftIntroTitle">{{ $t("SimpleTitle3") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("SimpleIntro3") }}</div>
                        </div>
                        <img class="SimpleItemImgReversal" src="@/assets/images/DistributionImg.png" alt="" />
                    </div>
                    <div class="SimpleItemReversal">
                        <div class="DigitalRightIntro DigitalLeftIntroductLittle EnvironmentallyLittle">
                            <div class="DigitalLeftIntroTitle">{{ $t("SimpleTitle4") }}</div>
                            <div class="DigitalLeftIntroDetail">{{ $t("SimpleIntro4") }}</div>
                        </div>
                        <img class="SimpleItemImgReversal" src="@/assets/images/WaitingImg.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="CamBookList">
            <div class="CamBookListBox">
                <div class="CamBookItem">
                    <img class="CamBookItemImg" src="@/assets/images/CamBookItem.png" alt="" />
                    <div class="CamBookItemTitle">{{ $t("BookName") }}</div> 
                </div>
                <div class="CamBookItem">
                    <img class="CamBookItemImg" src="@/assets/images/CamBookItem1.png" alt="" />
                    <div class="CamBookItemTitle">{{ $t("BookName1") }}</div> 
                </div>
                <div class="CamBookItem">
                    <img class="CamBookItemImg" src="@/assets/images/CamBookItem2.png" alt="" />
                    <div class="CamBookItemTitle">{{ $t("BookName2") }}</div> 
                </div>
                <div class="CamBookItem">
                    <img class="CamBookItemImg" src="@/assets/images/CamBookItem3.png" alt="" />
                    <div class="CamBookItemTitle">{{ $t("BookName3") }}</div> 
                </div>
                <div class="CamBookItem">
                    <img class="CamBookItemImg" src="@/assets/images/CamBookItem4.png" alt="" />
                    <div class="CamBookItemTitle">{{ $t("BookName4") }}</div> 
                </div>
                <div class="CamBookItem">
                    <img class="CamBookItemImg" src="@/assets/images/CamBookItem5.png" alt="" />
                    <div class="CamBookItemTitle">{{ $t("BookName5") }}</div> 
                </div>
                <div class="CamBookItem">
                    <img class="CamBookItemImg" src="@/assets/images/CamBookItem6.png" alt="" />
                    <div class="CamBookItemTitle">{{ $t("BookName6") }}</div> 
                </div>
                <div class="CamBookItem">
                    <img class="CamBookItemImg" src="@/assets/images/CamBookItem7.png" alt="" />
                    <div class="CamBookItemTitle">{{ $t("BookName7") }}</div> 
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="footerItem footerAbout">
                <div class="footerItemTitle">{{ $t("About") }}</div>
                <div class="borderline"></div>
                <div>{{ $t("AboutCamBook") }}</div>
                <div>{{ $t("Cooperative") }}</div>
                 <div>{{ $t("OperationTutorial") }}</div>
                <div>{{ $t("FeedbackInformation") }}</div>
                <div>{{ $t("Contect") }}</div>
            </div>
            <div class="footerItem">
                <div class="footerItemTitle">{{ $t("CamBookResources") }}</div>
                <div class="borderline"></div>
                <div>{{ $t("DigitalTextbooks") }}</div>
                <div>{{ $t("BookStore") }}</div>
            </div>

            <div class="footerItem">
                <div class="footerItemTitle">{{ $t("PartnerShip") }}</div>
                <div class="borderline"></div>
                <div>{{ $t("University") }}</div>
                <div>{{ $t("Publisher") }}</div>
            </div>
            <div class="footerItem">
                <div class="footerItemTitle">{{ $t("Download") }}</div>
                <div class="borderline"></div>
                <div>Android</div>
                <div>iOS</div>
            </div>
            <div class="footerItem">
                <img src="@/assets/images/weixin.png" alt="" />
                <img src="@/assets/images/microblog.png" alt="" />
                <img src="@/assets/images/Facebook.png" alt="" />
                <img src="@/assets/images/Twitter.png" alt="" />
            </div>
        </div>
        <div class="copyright">
             <div style="margin-bottom:10px">Copyright © 2021-2022 Qingdao Zhixuegu Education Technology Co., Ltd.  </div>
              <div style="color:#FFFFFF; display: flex;
              flex-direction: row;
              align-items: center;justify-content: center;margin-bottom: 10px; ">
                <img style="" src="@/assets/images/recordIcon.png" alt="" /><span style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; ">鲁公网安备 37020202001719号</span > 
                <a href="https://beian.miit.gov.cn/">鲁ICP备2022022872号-1</a>  
                </div>
            </div>
            
               
        <!-- <div class="copyright">Copyright © 2020-2021 Xiangming (Shanghai) Book & Culture Co.,Ltd. <a href="https://beian.miit.gov.cn/">沪ICP备18000851号-1</a></div> -->
    </div>
</template>

<script>
    import { defineComponent ,ref,reactive,onMounted} from 'vue';
    import './home.scss'
    export default defineComponent({
        setup() {
            const lanagelist=reactive([
                ['zh', '中文'],
                ['en', 'English'],
            ])
            const  lang=ref("English")
            const isChooseShow=ref(false)
           function chooesLange(languageItem,lang) {
               console.log("languageItem",languageItem)
               this.$i18n.locale = languageItem;
               this.isChooseShow=false
               this.lang=lang
               console.log("isChooseShow",this.isChooseShow)
           } 
           function chooseShow() {
               console.log(11,this.isChooseShow)
               this.isChooseShow=!this.isChooseShow
            }
            function goCambook(){   //去往cambook
               window.location.href='https://www.cambook.com.cn/web/#/Login'

            }
            return{chooesLange,lanagelist,chooseShow,isChooseShow,lang,goCambook}
        }
    })
</script>

<style lang='scss' scoped>
   
</style>